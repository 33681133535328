// @flow
import React from 'react'

import { Container } from 'reactstrap'
import Layout, { SEO } from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Seite nicht gefunden" />
    <Container>
      <h1>Uuups…</h1>
      <p>Die Seite konnte nicht gefunden werden.</p>
      <p>
        <a href="/" title="Startseite">
          Zurück zur Startseite
        </a>
      </p>
    </Container>
  </Layout>
)

export default NotFoundPage
